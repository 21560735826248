import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import useManifestation from './useManifestation';

import {
  PageConteiner,
  Conteiner,
  TitleRow,
  SubTitle,
  Label,
  FlexRow,
  TextField,
  Line,
  LoadingText
} from './manifestation-preview.style';

const mapRoomType = {
  parish: 'Parrocchiale',
  municipal: 'Municipale',
  private: 'Privata',
  'association headquarters': 'Sede Associazione',
  other: 'Altro'
};

const mapManfState = {
  'confirmed': {
    text: 'Programmata',
    color: '#F44E14'
  },
  'shifted': {
    text: 'Spostata',
    color: '#F9C82D'
  },
  'concluded': {
    text: 'Effetuata',
    color: '#39B54A'
  },
  'canceled': {
    text: 'Annullata',
    color: '#b40000'
  }
};

function ManifestationPreview() {
  const { id } = useParams();
  const history = useHistory();
  const { manifestation, fetchManifestation, loading } = useManifestation();
  const { startDate, startTime, inviteTime, agreementDate, location } = useMemo(() => {
    if (manifestation === null) return {};
    const { date, change_date, timetable_invitations, created_at, updated_at } = manifestation;
    const momentStartDate = moment(change_date ? change_date : date, 'DD/MM/YYYY hh:mm:ss');
    const momentAgreementDate = moment(updated_at ? updated_at : created_at, 'YYYY-MM-DD hh:mm:ss');
    return {
      startDate: momentStartDate.format('DD MM YYYY'),
      startTime: momentStartDate.format('hh:mm'),
      agreementDate: momentAgreementDate.format('DD MM YYYY'),
      inviteTime: timetable_invitations.slice(0, -3),
      location: manifestation.location
    };
  }, [manifestation]);

  useEffect(() => {
    fetchManifestation(id)
      .catch(() => history.goBack());
  }, [id, history, fetchManifestation]);

  return (
    <PageConteiner>
      {loading || manifestation === null ? <LoadingText /> : <>
        <TitleRow label='Manifestazione' button={mapManfState[manifestation.state]} />

        <Conteiner style={{ marginTop: 17 }}>
          <FlexRow style={{ justifyContent: 'flex-start' }}>
            <div style={{ marginRight: 40 }}>
              <TextField label='DATA' text={startDate} />
              <TextField label='Orario' text={startTime} />
            </div>
            <div>
              <TextField label='DATA PREACCORDO' text={agreementDate} />
              <TextField label='Orario Inviti' text={inviteTime} />
            </div>
          </FlexRow>

          <TextField label='Associazione' text={location.name} textStyle={{ color: '#B40000' }} />
          <TextField label='Località' text={location.place} />

          <FlexRow>
            <div>
              <TextField label='Via' text={location.road} />
              <TextField label='CAP' text={location.cap} />
            </div>
            <div>
              <TextField label='Numero' text={location.civic_number} />
              <TextField textStyle={{ textTransform: 'uppercase' }} label='Provincia' text={location.province} />
            </div>
          </FlexRow>

          <TextField label='Tipologia Sala' text={mapRoomType[manifestation.room_type]} />
        </Conteiner>

        <Line />

        <SubTitle>Descrizione contributo</SubTitle>
        <Conteiner style={{ marginTop: 35 }}>
          <Label style={{ marginBottom: 16 }}>
            CON
            <span style={{ color: '#000000' }}> {manifestation.num_couples} </span>
            COPPI{manifestation.num_couples === 1 ? 'A' : 'E'}
          </Label>
          <FlexRow>
            <TextField 
              label='Tot Contributo' 
              text={`${manifestation.tot_contribution?.toFixed(2).replace('.', ',')} €`} 
            />
            <TextField 
              label='Tot a coppia' 
              text={`${manifestation.tot_per_couple?.toFixed(2).replace('.', ',')} €`} 
            />
          </FlexRow>
          <TextField label='IBAN' text={manifestation.iban} />
          <TextField label='Codice Fiscale' text={manifestation.fiscal_code} />
          <TextField label='Note' text={manifestation.note} />
        </Conteiner>
      </>}
    </PageConteiner>
  );
}

export default ManifestationPreview;
