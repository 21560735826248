import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import it from "date-fns/locale/it";
import "moment/locale/it";
// services import
import Api from "./services/api.service";
import StorageService from "./services/storage.service";
import AppContext from "./services/context.service";
import { AuthRoute, PrivateRoute } from "./services/custom-routes.service";
// screens import
import Home from "./screens/home/home.screen";
import Login from "./screens/login/login.screen";
import RecoverPassword from "./screens/login/recover-password.screen";
import PasswordReset from "./screens/login/reset-password-landing-page.screen";
import CalendarScreen from "./screens/calendar/calendar.screen";
import ManifestationPreview from "./screens/manifestation-preview/manifestation-preview.screen";
import CompletedNotification from "./screens/completed-notification/completed-notification.screen";
import Achievements from "./screens/achievements/achievements.screen";
import Achievements2 from "./screens/achievements/achievements2.screen";
import Profile from "./screens/profile/profile.screen";
import Teams from "./screens/my-team/team.screen";
import ListMembers from "./screens/my-team/list_members.screen";
import LeaderboardListScreen from "./screens/leaderboard-list/leaderboard-list.screen";
import Leaderboard from "./screens/leaderboard/leaderboard.screen";
import RollOfHonor from "./screens/roll-of-honor/roll-of-honor.screen";
import CompletedLeaderboardsScreen from "./screens/completed-leaderboards/completed-leaderboards.screen";
// layout import
import Header from "./layout/header/header.layout";
import AddAssociation from "./screens/add_association/add_association.screen";
import CompleteAddAssociation from "./screens/add_association/add_complete.screen";
import AssociationsList from "./screens/association_list/association_list.screen";
import Association from "./screens/association_screen/association.screen";
import Footer from "./layout/footer/footer.layout";
import { FixedButton, HeadArrow } from "./screens/home/home.style";
// components import
import ManifestationModal from "./components/manifestation-modal/manifestation-modal.component";
// stylesheet import
import "./app.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import EditAssociation from "./screens/add_association/edit_association.screen";
import { QueryClient, QueryClientProvider } from "react-query";

// Set Moment lang to Italian
moment.locale("it");
// Set DatePicker locale
registerLocale("it", it);
setDefaultLocale("it");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [modal, setModal] = useState({ visible: false });
  const [modalState, setModalState] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [fixedButton, setFixedButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const toggleVisibility = () => {
    if (window.pageYOffset === 0) {
      setFixedButton(false);
    }
    if (window.pageYOffset > 100) {
      setFixedButton(true);
    }
  };

  const login = async (username, password, stayConnected) => {
    const {
      data: { token },
    } = await Api.login(username, password);
    if (stayConnected) StorageService.setToken(token);
    Api.setTemporaryToken(token);
    await fetchUser();
    setLoggedIn(true);
  };

  const requestPassword = async (email) => {
    await Api.requestPasswordReset(email);
  };

  const resetPassword = async (email, token, password, repassword) => {
    await Api.passwordReset(email, token, password, repassword);
  };

  const fetchUser = useCallback(async () => {
    const {
      data: { user },
    } = await Api.getProfile();
    delete user.manager;
    delete user.locations;
    delete user.events;
    setUser(user);
  }, [setUser]);

  const isLogged = useCallback(async () => {
    if (!StorageService.getToken()) return false;
    try {
      await fetchUser();
    } catch (e) {
      return false;
    }
    return true;
  }, [fetchUser]);

  const openManifestationModal = useCallback((data, onSubmit) => {
    setModal({
      visible: true,
      onSubmit,
      data,
    });
  }, []);

  const saveManifestationModalState = useCallback((newState) => {
    setModalState(newState);
  }, []);

  const openLastManifestationModal = useCallback(
    (additionalData) => {
      if (modalState !== null) {
        setModal({
          visible: true,
          data: { create: true, ...modalState, ...additionalData },
        });
        setModalState(null);
      }
    },
    [modalState]
  );

  const logout = () => {
    StorageService.removeToken();
    setLoggedIn(false);
  };

  useEffect(() => {
    isLogged().then((flag) => {
      setLoggedIn(flag);
      setLoading(false);
    });
    Api.on401TokenError((_) => {
      logout();
      alert("Sessione scaduta");
    });
  }, [isLogged]);

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  });

  const getContext = () => ({
    user,
    fetchUser,
    openManifestationModal,
    saveManifestationModalState,
    openLastManifestationModal,
    login,
    logout,
    requestPassword,
    resetPassword,
  });

  if (loading) return null;
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider value={getContext()}>
          {loggedIn && <Header />}
          {fixedButton && (
            <FixedButton onClick={() => scrollToTop()}>
              <HeadArrow />
            </FixedButton>
          )}
          <Switch>
            <AuthRoute
              isAuthenticated={loggedIn}
              path="/login"
              component={Login}
            />
            <AuthRoute
              isAuthenticated={loggedIn}
              path="/recover"
              component={RecoverPassword}
            />
            <AuthRoute
              isAuthenticated={loggedIn}
              path="/password/reset"
              component={PasswordReset}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/addassociation"
              component={AddAssociation}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/editassociation/:id"
              component={EditAssociation}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/add_complete"
              component={CompleteAddAssociation}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/associations_list"
              component={AssociationsList}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/association"
              component={Association}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/achievements"
              component={Achievements}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/achievements2"
              component={Achievements2}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/profile"
              component={Profile}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/teams"
              component={Teams}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              exact
              path="/calendar/:id"
              component={CalendarScreen}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/manifestation/:id"
              component={ManifestationPreview}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/completed/:type"
              component={CompletedNotification}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/leaderboard/list"
              component={LeaderboardListScreen}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/leaderboard/:type/:interval/:final"
              component={Leaderboard}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/leaderboard/rollofhonor"
              component={RollOfHonor}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="/leaderboard/completed"
              component={CompletedLeaderboardsScreen}
            />
            <PrivateRoute
              isAuthenticated={loggedIn}
              path="*"
              component={Home}
            />
          </Switch>
          <Footer />
          {loggedIn && (
            <ManifestationModal
              {...modal}
              hideModal={() => setModal({ visible: false })}
            />
          )}
        </AppContext.Provider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
