/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-sparse-arrays */
import React, { useState, useEffect, useRef } from "react";
import Container from "../../components/container.component";
import ProgressBar from "../../components/progressbar.component";
import Reward from "../../components/reward.component";
import Medals from "../../components/medals/medals";
import Chart from "../../components/chart/chart.component";
import GradientButton from "../../components/gradient.button";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import Api from "../../services/api.service";
import Loader from "react-loader-spinner";
import moment from "moment";
import imageCompression from "browser-image-compression";
import {
  Body,
  UserWrapper,
  Username,
  Role,
  UserInfo,
  TeamWrapper,
  TeamText,
  TeamName,
  IpohWrapper,
  IpohText,
  IpohTime,
  LeaderboardWrapper,
  Leaderboard,
  LeaderboardPosition,
  AssociationsNumber,
  LeaderboardPositionWrapper,
  UpArrow,
  DownArrow,
  RegisteredAssociationsText,
  RegisteredAssociationsWrapper,
  ProfilePlus,
  MegaphoneIcon,
  GeneralWrapper,
  SwitchWrapper,
  SwitchText,
  ExpBarWrapper,
  ExpTextWrapper,
  ExpText,
  RewardWrapper,
  Title,
  ShowAllWrapper,
  LoadFile,
  Popup,
  PopupButtonWrapper,
  Overlay,
  PopupTextWrapper,
  BlackText,
  RegisteredManifestationsText,
  ManifestationsNumber,
  RegisteredManifestationsWrapper
} from "./home.style";
import {
  Wrapper
} from "../../components/reward.component.style.js";

function Home() {
  const history = useHistory();
  const [profile, setProfile] = useState();
  const [date, setDate] = useState();
  const [leaderboardType, setLeaderboardType] = useState("month");
  const [leaderboard, setLeaderboard] = useState();
  const [chartType, setChartType] = useState("month");
  const [options, setOptions] = useState();
  const [perc, setPerc] = useState();
  const [badges, setBadges] = useState();
  const [team, setTeam] = useState();
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState(false);

  const changePic = useRef();

  const optionsImage = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const getBadges = async () => {
    try {
      const res = await Api.getBadges();
      setBadges(res.data.unlocked.slice(0, 3));
    } catch (e) {
      // console.log(e.message);
    }
  };

  const visualizeAll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history.push("/achievements2");
  };

  const getProfile = async () => {
    try {
      const res = await Api.getProfile();
      // console.log('>>> profile:', res.data);
      
      setProfile(res.data);
      setExperience(res.data);
    } catch (e) {
      // console.log(e.message);
    }
  };

  const closePopup = async () => {
    setPopup(false);
    const res = await Api.getProfile();
    setProfile(res.data);
  };

  const setExperience = (profile) => {
    if (profile.user.level === 1) {
      setPerc((profile.user.experience / 500) * 100 + "%");
    }
    if (profile.user.level === 2) {
      setPerc((profile.user.experience / 1000) * 100 + "%");
    }
    if (profile.user.level === 3) {
      setPerc((profile.user.experience / 2000) * 100 + "%");
    }
    if (profile.user.level === 4) {
      setPerc((profile.user.experience / 5000) * 100 + "%");
    }
    if (profile.user.level === 5) {
      setPerc((profile.user.experience / 10000) * 100 + "%");
    }
    if (profile.user.level === 6) {
      setPerc("100%");
    }
  };

  useEffect(() => {
    getProfile();
    getBadges();
  }, []);

  const loadFile = async (e) => {
    try {
      const compressedFile = await imageCompression(e[0], optionsImage);
      await Api.updateProfile(compressedFile);
      setPopup(true);
    } catch (e) {
      setError(true);
      //console.debug(e.message);
    }
  };

  const fetchLists = async (type) => {
    const dateMin = moment().startOf(type);
    const dateMax = moment().endOf(type);
    const [{ data: locData }, { data: evtData }] = await Promise.all([
      Api.leaderboardLocations(dateMin, dateMax),
      Api.leaderboardEvents(dateMin, dateMax),
    ]);
    
    setLeaderboard({
      locations: locData.my_result || {},
      events: evtData.my_result || {},
    });
  };

  const changeLeaderboard = (type) => {
    setLeaderboardType(type ? "year" : "month");
    fetchLists(type ? "year" : "month");
  };

  const changeChart = (type) => {
    if (type === "month") {
      let months = {
        1: { locations: null, events: null },
        2: { locations: null, events: null },
        3: { locations: null, events: null },
        4: { locations: null, events: null },
        5: { locations: null, events: null },
        6: { locations: null, events: null },
        7: { locations: null, events: null },
        8: { locations: null, events: null },
        9: { locations: null, events: null },
        10: { locations: null, events: null },
        11: { locations: null, events: null },
        12: { locations: null, events: null },
      };
      const filteredLocations = profile.location_leaderboard.statistics_by_month.filter(
        (key) => {
          return key.year === moment().year();
        }
      );
      const filteredEvents = profile.event_leaderboard.statistics_by_month.filter(
        (key) => {
          return key.year === moment().year();
        }
      );
      filteredLocations.map((month) => {
        months[month.month].locations = month.total;
      });
      filteredEvents.map((month) => {
        months[month.month].events = month.total;
      });
      setChartType("month");
      setOptions({
        data: {
          labels: [
            "",
            "gen",
            "feb",
            "mar",
            "apr",
            "mag",
            "giu",
            "lug",
            "ago",
            "set",
            "ott",
            "nov",
            "dic",
          ],
          datasets: [
            {
              label: "  Associazioni",
              backgroundColor: "#B40000",
              borderColor: "#B40000",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: [
                ,
                months[1].locations,
                months[2].locations,
                months[3].locations,
                months[4].locations,
                months[5].locations,
                months[6].locations,
                months[7].locations,
                months[8].locations,
                months[9].locations,
                months[10].locations,
                months[11].locations,
                months[12].locations,
              ],
            },
            {
              label: "  Manifestazioni",
              backgroundColor: "#0071BC",
              borderColor: "#0071BC",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: [
                ,
                months[1].events,
                months[2].events,
                months[3].events,
                months[4].events,
                months[5].events,
                months[6].events,
                months[7].events,
                months[8].events,
                months[9].events,
                months[10].events,
                months[11].events,
                months[12].events,
              ],
            },
          ],
        },
      });
    } else if (type === "year") {
      let years = {};
      profile.location_leaderboard.statistics_by_year.map((year) => {
        years[year.year] = { ...years[year.year], locations: year.total };
      });
      profile.event_leaderboard.statistics_by_year.map((year) => {
        years[year.year] = { ...years[year.year], events: year.total };
      });
      const emptyYear = { locations: 0, events: 0 };
      const yearsKeys = Object.keys(years);
      if(yearsKeys.length > 1) {
        for(let i = yearsKeys[0]; i < yearsKeys[yearsKeys.length - 1]; i++) {
          if(i !== yearsKeys[0] && i !== yearsKeys[yearsKeys.length - 1]) years[i] = emptyYear;
        }
      }
      setChartType("year");
      setOptions({
        data: {
          labels: Object.keys(years),
          datasets: [
            {
              label: "  Associazioni",
              backgroundColor: "#B40000",
              borderColor: "#B40000",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: Object.values(years).map(y => y.locations),
            },
            {
              label: "  Manifestazioni",
              backgroundColor: "#0071BC",
              borderColor: "#0071BC",
              fill: false,
              lineTension: 0,
              borderWidth: 2,
              pointBorderWidth: 6,
              pointHoverBorderWidth: 6,
              data: Object.values(years).map(y => y.events),
            },
          ],
        },
      });
    }
  };

  const teamRetriever = async (id) => {
    const res = await Api.getProfile(profile.user.tutor_id);
    setTeam(res.data.user.name);
  };

  useEffect(() => {
    if (profile !== undefined) {
      setDate(moment(profile.user.created_at).format("ll").toUpperCase().split(" "));
      if (profile.user.role === "organizer") {
        if(profile.user.tutor_id) {
          teamRetriever(profile.user.tutor_id);
          // setTeam(profile.user.tutor.name);
        } else {
          if(profile.user.manager_id && profile.user.manager) {
            setTeam(profile.user.manager.name);
          }
        }
      } else {
        setTeam(profile.user.manager.name);
      }
      changeChart("month");
    }
    fetchLists(leaderboardType);
  }, [profile]);

  if (profile && leaderboard && options && badges) {
    return (
      <Body>
        {popup && (
          <>
            <Overlay />
            <Popup>
              <PopupTextWrapper>
                {error ? (
                  <BlackText>
                    Qualcosa è andato storto. Ritenta l'upload
                  </BlackText>
                ) : (
                    <BlackText>Upload effettuato con successo!</BlackText>
                  )}
              </PopupTextWrapper>
              <PopupButtonWrapper onClick={() => closePopup()}>
                <GradientButton text={"Ok!"} />
              </PopupButtonWrapper>
            </Popup>
          </>
        )}
        <Container
          title={"Informazioni personali"}
          content={
            <>
              <UserWrapper>
                <LoadFile
                  id="file"
                  type="file"
                  accept=".jpg, .png"
                  onChange={(e) => loadFile(e.target.files)}
                  ref={changePic}
                />
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => changePic.current.click()}
                >
                  <div
                    style={{
                      width: 127,
                      height: 127,
                      borderRadius: 100,
                      borderWidth: 4,
                      borderStyle: "solid",
                      borderColor: "#b40000",
                      backgroundImage: `url(${
                        "https://admin.ipoh.app/" + profile.user.user_pic_url
                        })`,
                      backgroundSize: "cover",
                    }}
                  />
                </div>
                <Username>{profile.user.name}</Username>
                <Role>
                  {profile.user.role === "organizer"
                    ? "organizzatore"
                    : profile.user.role === "manager"
                      ? "responsabile"
                      : profile.user.role}
                </Role>
              </UserWrapper>
              <UserInfo>
                <IpohWrapper>
                  <IpohText>In Ipoh da</IpohText>
                  <IpohTime>{profile.user.assumption_date}</IpohTime>
                </IpohWrapper>
              </UserInfo>
              <Medals profile={profile} />
            </>
          }
        ></Container>
        <Container
          title={"Situazione Classifiche"}
          isTime
          leaderboardType={leaderboardType}
          content={
            <>
              <GeneralWrapper>
                <LeaderboardWrapper>
                  <Leaderboard style={{ marginRight: 17, marginLeft: 14.5 }}>
                    <LeaderboardPositionWrapper>
                      <LeaderboardPosition>
                        Pos in classifica{" "}
                        {leaderboard.locations !== "N/D"
                          ? leaderboard.locations.position
                          : 0}
                        °
                      </LeaderboardPosition>
                      {/*<UpArrow>*/}
                    </LeaderboardPositionWrapper>
                    <AssociationsNumber>
                      {leaderboard.locations !== "N/D"
                        ? leaderboard.locations.total
                        : 0}
                    </AssociationsNumber>
                    <RegisteredAssociationsWrapper>
                      <ProfilePlus />
                      <RegisteredAssociationsText>
                        Associazioni Registrate
                      </RegisteredAssociationsText>
                    </RegisteredAssociationsWrapper>
                  </Leaderboard>
                  <Leaderboard style={{ marginRight: 15 }}>
                    <LeaderboardPositionWrapper>
                      <LeaderboardPosition>
                        Pos in classifica{" "}
                        {leaderboard.events !== "N/D"
                          ? leaderboard.events.position
                          : 0}
                        °
                      </LeaderboardPosition>
                      {/*<DownArrow>*/}
                    </LeaderboardPositionWrapper>
                    <ManifestationsNumber>
                      {leaderboard.events !== "N/D"
                        ? leaderboard.events.total
                        : 0}
                    </ManifestationsNumber>
                    <RegisteredManifestationsWrapper>
                      <MegaphoneIcon />
                      <RegisteredManifestationsText>
                        Manifestazioni Effettuate
                      </RegisteredManifestationsText>
                    </RegisteredManifestationsWrapper>
                  </Leaderboard>
                </LeaderboardWrapper>
                <SwitchWrapper>
                  <SwitchText>MENSILE</SwitchText>
                  <div style={{ marginLeft: 6, marginRight: 6 }}>
                    <Switch
                      checked={leaderboardType === "year"}
                      offColor={"#B40000"}
                      onColor={"#B40000"}
                      height={25}
                      width={43}
                      handleDiameter={25}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onChange={(type) => changeLeaderboard(type)}
                      activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    />
                  </div>
                  <SwitchText>ANNUALE</SwitchText>
                </SwitchWrapper>
              </GeneralWrapper>
            </>
          }
        ></Container>
        <Container
          title={"Prossimo Livello"}
          isLevel
          level={profile.user.level}
          content={
            <>
              <ExpTextWrapper>
                {profile.user.level === 1 && (
                  <>
                    <ExpText>0 xp</ExpText> <ExpText>500 xp</ExpText>
                  </>
                )}
                {profile.user.level === 2 && (
                  <>
                    <ExpText>500 xp</ExpText> <ExpText>1000 xp</ExpText>
                  </>
                )}
                {profile.user.level === 3 && (
                  <>
                    <ExpText>1000 xp</ExpText> <ExpText>2000 xp</ExpText>
                  </>
                )}
                {profile.user.level === 4 && (
                  <>
                    <ExpText>2000 xp</ExpText> <ExpText>5000 xp</ExpText>
                  </>
                )}
                {profile.user.level === 5 && (
                  <>
                    <ExpText>5000 xp</ExpText> <ExpText>10000 xp</ExpText>
                  </>
                )}
                {profile.user.level === 6 && <ExpText>10000 xp</ExpText>}
              </ExpTextWrapper>
              <ExpBarWrapper>
                <ProgressBar width={perc} />
              </ExpBarWrapper>
            </>
          }
        ></Container>
        <Title>Ricompense</Title>
        <RewardWrapper>
          {badges.length > 0 ? badges.map((badge) => {
            return (
              <Reward
                icon={`https://admin.ipoh.app/${badge.icon.url}`}
                title={badge.name}
                description={badge.description}
                windowMode
              />
            );
          }) : <Wrapper><div style={{ height: 50 }} /></Wrapper>}
        </RewardWrapper>
        <ShowAllWrapper onClick={() => visualizeAll()}>
          <GradientButton text={"Visualizza tutti"} />
        </ShowAllWrapper>
        <Container
          title={"Statistiche"}
          isStats
          type={chartType}
          setType={changeChart}
          content={
            <>
              <Chart options={options} />
            </>
          }
        ></Container>
      </Body>
    );
  } else
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          marginTop: "20%",
        }}
      >
        <Loader type="Oval" color="#B40000" height={100} width={100} />
      </div>
    );
}

export default Home;
