import axios from "axios";
import StorageService from "./storage.service";
import Province from "../assets/province.txt";

axios.defaults.baseURL = "https://admin.ipoh.app/api/v1";
// axios.defaults.baseURL = "http://localhost:8000/api/v1/"; // DEBUG
axios.defaults.headers.common.Accept = "application/json";

class Api {
  static _temporaryToken = undefined;

  static request = async (
    method,
    url,
    params = {},
    headers = {},
    data = {},
    useToken
  ) => {
    if (useToken) {
      const token = this._temporaryToken || StorageService.getToken();
      headers.Authorization = `Bearer ${token}`;
    }
    return axios({ method, url, params, headers, data });
  };

  static get = async (url, opt = {}, useToken = false) =>
    this.request("get", url, opt.params, opt.headers, opt.data, useToken);
  static post = async (url, opt = {}, useToken = false) =>
    this.request("post", url, opt.params, opt.headers, opt.data, useToken);
  static delete = async (url, opt = {}, useToken = false) =>
    this.request("delete", url, opt.params, opt.headers, opt.data, useToken);
  static patch = async (url, opt = {}, useToken = false) =>
    this.request("patch", url, opt.params, opt.headers, opt.data, useToken);
  static put = async (url, opt = {}, useToken = false) =>
    this.request("put", url, opt.params, opt.headers, opt.data, useToken);

  // GET requests
  static getProfile = async (id) =>
    this.get("/user/profile", { params: { userId: id } }, true);
  static getLocations = async (page, id) =>
    this.get(
      "/location/list",
      { params: { page: page, created_by_id: id } },
      true
    );
  static getLocation = async (id) =>
    this.get("/location/" + id + "/show", {}, true);
  static listUsers = async (team) =>
    this.get("/user/list", { params: { team } }, true);
  static getGroup = async (id) =>
    this.get("/group/" + id, {}, true);
  static getAvailability = async (locationId, date) =>
    this.post("/event/availability", { data: { location_id: locationId, date } }, true);
  static getLocationOptions = async () => {
    const resp = await this.getPlaces();
    const { data } = resp;
    return data.map(location => ({ value: location.place_code, label: location.place_code }));
  };
  static getProvinceOptions = async () => {
    const resp = await this.getProvinces();
    const { data } = resp;
    return data.map(province => ({ value: province.province_code, label: province.province_description }));
  };
  static getRegionOptions = async () => {
    const resp = await this.getRegions();
    const { data } = resp;
    return data.map(region => ({ value: region.region_code, label: region.region_description }));
  };
  static getManifestation = async (id) =>
    this.get(`event/${id}/show`, {}, true);
  static searchLocationOptions = async (name) => this._searchFrom(this.getLocationOptions, name);
  static searchProvinceOptions = async (name) => this._searchFrom(this.getProvinceOptions, name);
  static searchRegionOptions = async (name) => this._searchFrom(this.getRegionOptions, name);
  static searchLocation = async (query, page, id) =>
    this.get(
      "/location/search",
      {
        params: { query, page, created_by_id: id },
      },
      true
    );
  static searchLocationByName = async (location_name, page, id) =>
    this.get(
      "/location/search",
      {
        params: { location_name, page, created_by_id: id },
      },
      true
    );
  static listEvent = async (userId, date1, date2, page = 1) =>
    this.get(
      "/event/list",
      {
        params: {
          user_id: userId,
          dateMin: date1.format("DD/MM/YYYY"),
          dateMax: date2.format("DD/MM/YYYY"),
          page,
        },
      },
      true
    );
  static leaderboardLocations = async (date1, date2) =>
    this.get(
      "/location/leaderboard",
      {
        params: {
          dateMin: date1.format("DD/MM/YYYY"),
          dateMax: date2.format("DD/MM/YYYY"),
        },
      },
      true
    );
  static leaderboardEvents = async (date1, date2, state = "concluded") =>
    this.get(
      "/event/leaderboard",
      {
        params: {
          state,
          dateMin: date1.format("DD/MM/YYYY"),
          dateMax: date2.format("DD/MM/YYYY"),
        },
      },
      true
    );
  static leaderboardTeamEvents = async (date1, date2, id, state = "concluded") =>
    this.get(
      "/event/leaderboard/team",
      {
        params: {
          dateMin: date1.format("DD/MM/YYYY"),
          dateMax: date2.format("DD/MM/YYYY"),
          managerId: id,
          state
        },
      },
      true
    );
  static getBadges = async (id) =>
    this.get("/user/profile/badges", { params: { userId: id } }, true);
  // POST requests
  static login = async (username, password) =>
    this.post("/login", { params: { username, password } });

  static createLocation = async (params) =>
    this.post(
      "/location/create",
      {
        // params: {
        data: {
          name: params.name,
          status: params.status,
          forma_giuridica: params.forma_giuridica,
          partita_iva: params.partita_iva,
          codice_fiscale: params.codice_fiscale,
          road: params.road,
          referents: params.referents,
          phone_1: params.phone_1,
          phone_2: params.phone2,
          email: params.email,
          note: params.note,
          num_members: params.num_members,
          place: params.place,
          type: params.type,
          photo: params.photo,
          additional_infos: {
            feedback: params.additional_infos.feedback,
            ideas: params.additional_infos.ideas,
            cost: params.additional_infos.cost,
            time: params.additional_infos.time,
          },
        },
      },
      true
    );

    static updateLocationData = async (id, params) =>
    this.post(
      `/location/${id}/update`,
      {
        // params: {
        data: {
          name: params.name,
          status: params.status,
          forma_giuridica: params.forma_giuridica,
          codice_fiscale: params.codice_fiscale,
          partita_iva: params.partita_iva,
          road: params.road,
          referents: params.referents,
          phone_1: params.phone_1,
          phone_2: params.phone2,
          email: params.email,
          note: params.note,
          num_members: params.num_members,
          place: params.place,
          type: params.type,
          photo: params.photo,
          additional_infos: {
            feedback: params.additional_infos.feedback,
            ideas: params.additional_infos.ideas,
            cost: params.additional_infos.cost,
            time: params.additional_infos.time,
          },
        },
      },
      true
    );


  static getPlaces = async (q) =>
    this.get(`/place/search?q=${q}`, {}, true);

  static getTypes = async (q) =>
    this.get(`/location-type/search?q=${q}`, {}, true);

  static updateProfile = async (photo) =>
    this.post(
      "/user/update",
      {
        data: this.createFormDataFromObject({ user_pic: photo }),
        headers: { "Content-Type": "multipart/form-data" },
      },
      true
    );
  static updateLocation = async (photo, id) =>
    this.post(
      "/location/" + id + "/update",
      {
        data: photo,
        headers: { "Content-Type": "multipart/form-data" },
      },
      true
    );
  static requestPasswordReset = async (email) =>
    this.post(
      "password/request-reset",
      {
        params: {
          email
        },
      }, true);
  static passwordReset = async (email, token, password, password_confirmation) =>
    this.post(
      "password/reset",
      {
        params: {
          email,
          token,
          password,
          password_confirmation
        },
      }, true);
  static login = async (email, password) =>
    this.post("/login", { params: { email, password } });

  static createEvent = async (data) =>
    this.post("event/create", { data }, true);

  // DELETE requests
  // PATCH requests
  // PUT requests
  // UTILS
  static setTemporaryToken = (value) => (this._temporaryToken = value);
  static createFormDataFromObject = (obj) => {
    const formData = new FormData();
    Object.entries(obj).forEach(([key, value]) => formData.set(key, value));
    return formData;
  };
  static on401TokenError = (callback) => {
    return axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        if (
          response &&
          response.status === 401 &&
          response.data.message === "Unauthenticated."
        )
          callback(response);
        return Promise.reject(error);
      }
    );
  };
  static removeInterceptor = (interceptor) =>
    axios.interceptors.request.eject(interceptor);
  static _searchFrom = async (fnc, name, limit = 10) => {
    const options = await fnc();
    let items = [];
    name = name.trim().toLowerCase();

    for (let i = 0; limit > 0 && i < options.length; i++) {
      if (options[i].label.toLowerCase().includes(name)) {
        items.push(options[i]);
        limit--;
      }
    }

    return items;
  }
}

export default Api;
