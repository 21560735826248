import React from "react";
import moment from "moment";
import Countdown from "react-countdown";
import {
  Wrapper,
  HeaderContainer,
  RedRectangle,
  TitleContainer,
  Time,
  Medal,
  LevelWrapper,
  LevelText,
  StatsWrapper,
  MonthText,
  YearText,
  TitleContainerAdd,
  IconContainer,
  IconWrapper,
  Edit,
  Profile,
  Done,
} from "./container.component.style";

function Container(props) {
  const {
    title,
    content,
    isTime,
    isLevel,
    isStats,
    isAdd,
    step,
    shorter,
    type,
    setType,
    leaderboardType,
    level,
    setStep,
  } = props;

  const renderer = (time) => {
    return (
      <span>
        -{time.days}g {time.hours}h {time.minutes}m
      </span>
    );
  };

  if (isAdd) {
    return (
      <Wrapper>
        <IconContainer>
          {step === 1 && (
            <>
              <IconWrapper isActive onClick={() => setStep(1)}>
                <Edit isActive />
              </IconWrapper>
              <IconWrapper onClick={() => setStep(2)}>
                <Profile />
              </IconWrapper>
              <IconWrapper onClick={() => setStep(3)}>
                <Done />
              </IconWrapper>
            </>
          )}
          {step === 2 && (
            <>
              <IconWrapper onClick={() => setStep(1)}>
                <Edit />
              </IconWrapper>
              <IconWrapper isActive onClick={() => setStep(2)}>
                <Profile isActive />
              </IconWrapper>
              <IconWrapper onClick={() => setStep(3)}>
                <Done />
              </IconWrapper>
            </>
          )}
          {step === 3 && (
            <>
              <IconWrapper onClick={() => setStep(1)}>
                <Edit />
              </IconWrapper>
              <IconWrapper onClick={() => setStep(2)}>
                <Profile />
              </IconWrapper>
              <IconWrapper isActive onClick={() => setStep(3)}>
                <Done isActive />
              </IconWrapper>
            </>
          )}
        </IconContainer>
        <HeaderContainer>
          <RedRectangle />
          <TitleContainerAdd shorter={shorter}>{title}</TitleContainerAdd>
        </HeaderContainer>
        {content}
      </Wrapper>
    );
  } else
    return (
      <Wrapper>
        <HeaderContainer>
          {title && (
            <>
              <RedRectangle />
              <TitleContainer>{title}</TitleContainer>
            </>
          )}
          {isTime && (
            <Time>
              <Countdown
                date={moment().endOf(leaderboardType)}
                renderer={renderer}
              />
            </Time>
          )}
          {isLevel && (
            <LevelWrapper>
              <Medal />
              {level === 1 && <LevelText>PRINCIPIANTE</LevelText>}
              {level === 2 && <LevelText>AMATORE</LevelText>}
              {level === 3 && <LevelText>ESPERTO</LevelText>}
              {level === 4 && <LevelText>VETERANO</LevelText>}
              {level === 5 && <LevelText>LEADER</LevelText>}
              {level === 6 && <LevelText>COMANDANTE</LevelText>}
            </LevelWrapper>
          )}
          {isStats && (
            <StatsWrapper>
              <div onClick={() => setType("month")}>
                <MonthText active={type === "month"}>MESE</MonthText>
              </div>
              <p style={{ fontSize: 12 }}>&nbsp;|&nbsp;</p>
              <div onClick={() => setType("year")}>
                <YearText active={type === "year"}>ANNO</YearText>
              </div>
            </StatsWrapper>
          )}
        </HeaderContainer>
        {content}
      </Wrapper>
    );
}

export default Container;
