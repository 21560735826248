import React from "react";
import { Line } from "react-chartjs-2";

function Chart(props) {
  const { options } = props;
  return (
    <div
      style={{
        width: '95%',
        height: 263,
        marginTop: 19,
        marginLeft: 10,
        marginRight: 0
      }}
    >
      <Line
        options={{
          scales: {
            yAxes: [
              {
                gridLines: {
                  drawTicks: false
                },
                ticks: {
                  fontSize: 12,
                  fontFamily: "Montserrat",
                  fontStyle: "bold",
                  fontColor: "black",
                  padding: 6,
                  suggestedMin: 0,
                  suggestedMax: 40
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  drawTicks: false
                },
                ticks: {
                  fontSize: 12,
                  fontFamily: "Montserrat",
                  fontStyle: "bold",
                  fontColor: "black",
                  padding: 8
                }
              }
            ]
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#808080",
              fontFamily: "Montserrat",
              fontStyle: "bold",
              padding: 14,
              fontSize: 12,
              usePointStyle: true
            }
          }
        }}
        data={options.data}
      />
    </div>
  );
}

export default Chart;
